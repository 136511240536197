import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 17">
      <path
        d="M16.2066 1L15.0369 16.2066L8.61078 9.6122L1 8.01844L16.2066 1Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.77344 9.01465L6.91704 15.6171L11.1027 12.1495"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.60156 9.58047L11.0398 7.62988" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
  )
}

export default Icon
