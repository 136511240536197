import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'HybridEx - A Decentralized Exchange on Hybrid Blockchain',
  description:
    'The State-of-the-Art of Decentralized Trading, merging the power of Hybrid blockchain with AI-driven insights.',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  let basePath
  if (path.startsWith('/swap')) {
    basePath = '/swap'
  } else if (path.startsWith('/add')) {
    basePath = '/add'
  } else if (path.startsWith('/remove')) {
    basePath = '/remove'
  } else if (path.startsWith('/teams')) {
    basePath = '/teams'
  } else if (path.startsWith('/voting/proposal') && path !== '/voting/proposal/create') {
    basePath = '/voting/proposal'
  } else if (path.startsWith('/nfts/collections')) {
    basePath = '/nfts/collections'
  } else if (path.startsWith('/nfts/profile')) {
    basePath = '/nfts/profile'
  } else if (path.startsWith('/pancake-squad')) {
    basePath = '/pancake-squad'
  } else {
    basePath = path
  }

  // switch (basePath) {
  //   case '/':
  //     return {
  //       title: `${t('Home')} | ${t(DEFAULT_META.title)}`,
  //     }
  //   case '/swap':
  //     return {
  //       title: `${t('Exchange')} | ${t(DEFAULT_META.title)}`,
  //     }
  //   case '/add':
  //     return {
  //       title: `${t('Add Liquidity')} | ${t(DEFAULT_META.title)}`,
  //     }
  //   case '/remove':
  //     return {
  //       title: `${t('Remove Liquidity')} | ${t(DEFAULT_META.title)}`,
  //     }
  //   case '/liquidity':
  //     return {
  //       title: `${t('Liquidity')} | ${t(DEFAULT_META.title)}`,
  //     }
  //   case '/find':
  //     return {
  //       title: `${t('Import Pool')} | ${t(DEFAULT_META.title)}`,
  //     }
  //   case '/competition':
  //     return {
  //       title: `${t('Trading Battle')} | ${t(DEFAULT_META.title)}`,
  //     }
  //   case '/prediction':
  //     return {
  //       title: `${t('Prediction')} | ${t(DEFAULT_META.title)}`,
  //     }
  //   case '/prediction/leaderboard':
  //     return {
  //       title: `${t('Leaderboard')} | ${t(DEFAULT_META.title)}`,
  //     }
  //   case '/farms':
  //     return {
  //       title: `${t('Farms')} | ${t(DEFAULT_META.title)}`,
  //     }
  //   case '/farms/auction':
  //     return {
  //       title: `${t('Farm Auctions')} | ${t(DEFAULT_META.title)}`,
  //     }
  //   case '/pools':
  //     return {
  //       title: `${t('Pools')} | ${t(DEFAULT_META.title)}`,
  //     }
  //   case '/lottery':
  //     return {
  //       title: `${t('Lottery')} | ${t(DEFAULT_META.title)}`,
  //     }
  //   case '/ifo':
  //     return {
  //       title: `${t('Initial Farm Offering')} | ${t(DEFAULT_META.title)}`,
  //     }
  //   case '/teams':
  //     return {
  //       title: `${t('Leaderboard')} | ${t(DEFAULT_META.title)}`,
  //     }
  //   case '/voting':
  //     return {
  //       title: `${t('Voting')} | ${t(DEFAULT_META.title)}`,
  //     }
  //   case '/voting/proposal':
  //     return {
  //       title: `${t('Proposals')} | ${t(DEFAULT_META.title)}`,
  //     }
  //   case '/voting/proposal/create':
  //     return {
  //       title: `${t('Make a Proposal')} | ${t(DEFAULT_META.title)}`,
  //     }
  //   case '/info':
  //     return {
  //       title: `${t('Overview')} | ${t(DEFAULT_META.title)}`,
  //     }
  //   case '/info/pools':
  //     return {
  //       title: `${t('Pools')} | ${t(DEFAULT_META.title)}`,
  //     }
  //   case '/info/tokens':
  //     return {
  //       title: `${t('Tokens')} | ${t(DEFAULT_META.title)}`,
  //     }
  //   case '/nfts':
  //     return {
  //       title: `${t('Overview')} | ${t(DEFAULT_META.title)}`,
  //     }
  //   case '/nfts/collections':
  //     return {
  //       title: `${t('Collections')} | ${t(DEFAULT_META.title)}`,
  //     }
  //   case '/nfts/activity':
  //     return {
  //       title: `${t('Activity')} | ${t(DEFAULT_META.title)}`,
  //     }
  //   case '/nfts/profile':
  //     return {
  //       title: `${t('Profile')} | ${t(DEFAULT_META.title)}`,
  //     }
  //   case '/pancake-squad':
  //     return {
  //       title: `${t('Squad')} | ${t(DEFAULT_META.title)}`,
  //     }
  //   default:
  //     return null
  // }
}
