import React from 'react'
import Link from './Link'
import { LinkProps } from './types'
import OpenNewIcon from '../Svg/Icons/OpenNew'

const LinkExternal: React.FC<LinkProps> = ({
  children,
  margin = true,
  iconWidth = '20px',
  iconColor = 'white',
  ...props
}) => {
  return (
    <Link external {...props}>
      {children}
      <OpenNewIcon color={iconColor} width={iconWidth} ml={margin ? '6px' : '0px'} />
    </Link>
  )
}

export default LinkExternal
