import { Link, Text, Box } from 'packages/uikit'
import { getBscScanLink } from 'utils'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTranslation } from 'contexts/Localization'
import truncateHash from 'utils/truncateHash'

interface DescriptionWithTxProps {
  description?: string
  txHash?: string
}

const DescriptionWithTx: React.FC<DescriptionWithTxProps> = ({ txHash, children }) => {
  const { chainId } = useActiveWeb3React()
  const { t } = useTranslation()

  return (
    <>
      {typeof children === 'string' ? (
        <Box mb="6px">
          <Text as="p" color="black" small>
            {children}
          </Text>
        </Box>
      ) : (
        <Box mb="6px">{children}</Box>
      )}
      {txHash && (
        <Link color="black" external href={getBscScanLink(txHash, 'transaction', chainId)}>
          <Text as="p" color="black" small>
            {t('View on Hybrid Explorer')}: {truncateHash(txHash, 8, 0)}
          </Text>
        </Link>
      )}
    </>
  )
}

export default DescriptionWithTx
