/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  // createElement,
  memo,
} from 'react'
import styled from 'styled-components'
import { Flex } from '../Box'
import isTouchDevice from '../../util/isTouchDevice'
import DropdownMenu from '../DropdownMenu/DropdownMenu'
import MenuItem from '../MenuItem/MenuItem'
import { MenuItemsProps } from './types'
import { Text } from '../Text'
import { ChevronDownIcon } from '../Svg'
// import AnimatedIconComponent from '../Svg/AnimatedIconComponent'

// const StyledIconWrapper = styled.div<{ isActive?: boolean }>`
//   display: flex;
//   align-items: center;
//   margin-right: 8px;
// `

// const SoonLabel = styled(Text)`
//   position: absolute;
//   right: 0;
//   top: 0;
//   transform: translate(calc(100% + 5px), -50%);
// `

const StyledFlex = styled(Flex)`
  border-radius: 20px;
  gap: 26px;
  align-items: center;
`

const MenuItems: React.FC<MenuItemsProps> = ({ items = [], activeItem, activeSubItem, ...props }) => {
  return (
    <StyledFlex {...props}>
      {items.map(({ label, items: menuItems = [], href, icon, fillIcon, commingSoon, type }, i) => {
        const statusColor = menuItems?.find((menuItem) => menuItem.status !== undefined)?.status?.color
        const isActive = activeItem === href
        const linkProps = isTouchDevice() && menuItems && menuItems.length > 0 ? {} : { href }
        const Icon = icon
        return (
          <>
            <DropdownMenu key={`${label}#${href}`} items={menuItems} py={1} activeItem={activeSubItem}>
              <MenuItem
                {...linkProps}
                isActive={isActive}
                statusColor={statusColor}
                isComming={commingSoon}
                type={type}
                key={i}
              >
                {/* {icon && (
                  <StyledIconWrapper isActive={isActive}>
                    <AnimatedIconComponent icon={icon} fillIcon={fillIcon} width="25px" height="25px" isActive={isActive} />
                  </StyledIconWrapper>
                )} */}
                <Text color={isActive ? 'primary' : 'white'} fontSize="18px">
                  {label}
                </Text>

                {menuItems.length > 0 ? <ChevronDownIcon width="26px" /> : null}
              </MenuItem>
            </DropdownMenu>
          </>
        )
      })}
    </StyledFlex>
  )
}

export default memo(MenuItems)
