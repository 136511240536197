import styled from 'styled-components'
import { darkColors } from '../../theme/colors'
import { Box, Flex } from '../Box'
import SocialLinks from './Components/SocialLinks'

export const StyledFooter = styled(Flex).attrs({
  flexDirection: ['column', , , , 'row'],
  alignItems: ['flex-start', , , , 'flex-end'],
})`
  background: transparent;
  margin: auto;
  height: 100%;
  padding: 25px;
`

export const StyledList = styled.ul`
  list-style: none;
  margin-bottom: 40px;

  ${({ theme }) => theme.mediaQueries.md} {
    margin-bottom: 0px;
  }
`

export const SocialWrapper = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  width: 32,
  height: 32,
})`
  * > svg {
    path {
      stroke: black !important;
    }
  }
`

export const RobotWrapper = styled.div`
  position: absolute;
  bottom: 15px;
  right: 5px;
  max-width: 90px;

  ${({ theme }) => theme.mediaQueries.lg} {
    bottom: 15px;
    right: 25px;
    max-width: 250px;
  }
`
