import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 12">
      <path d="M1 1L11 11" stroke="rgba(244, 59, 58, 1)" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5.22656 2.92307L6.35299 1.79656C7.41507 0.73448 9.13702 0.73448 10.1992 1.79656C11.2612 2.85865 11.2612 4.58062 10.1992 5.64271L9.07271 6.76922"
        stroke="rgba(244, 59, 58, 1)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.76914 9.07661L5.64271 10.2032C4.58062 11.2652 2.85865 11.2652 1.79656 10.2032C0.734479 9.14108 0.73448 7.41906 1.79656 6.35697L2.92299 5.23047"
        stroke="rgba(244, 59, 58, 1)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.53846 4.46191L6 6.00037" stroke="rgba(244, 59, 58, 1)" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M4.46094 7.53891L4.84555 7.1543"
        stroke="rgba(244, 59, 58, 1)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Icon
