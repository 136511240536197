import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 12">
      <path
        d="M4.11372 11.4997H10.7248C11.1544 11.4997 11.5026 11.1515 11.5026 10.7219V4.11079C11.5026 3.68123 11.1544 3.33301 10.7248 3.33301H4.11372C3.68416 3.33301 3.33594 3.68123 3.33594 4.11079V10.7219C3.33594 11.1515 3.68416 11.4997 4.11372 11.4997Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.16667 8.77778H2C1.44772 8.77778 1 8.33006 1 7.77778V1.77778C1 1.5715 1.08194 1.37367 1.22781 1.22781C1.37367 1.08194 1.5715 1 1.77778 1H7.77778C8.33006 1 8.77778 1.44772 8.77778 2V2.16667"
        stroke="white"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Icon
