import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 10 10" {...props}>
      <path
        d="M0 9.10599C0.00322604 9.26654 0.0577093 9.42323 0.157519 9.55904C0.257328 9.69486 0.398654 9.80464 0.56613 9.87636C0.733372 9.9574 0.922199 10 1.11429 10C1.30638 10 1.49521 9.9574 1.66244 9.87636L9.43549 5.75494C9.60461 5.68509 9.74731 5.57574 9.84724 5.43946C9.94707 5.30318 10 5.14551 10 4.98458C10 4.82364 9.94707 4.66597 9.84724 4.52969C9.74731 4.39342 9.60461 4.28406 9.43549 4.21421L1.66244 0.123579C1.49521 0.0425703 1.30638 0 1.11429 0C0.922199 0 0.733372 0.0425703 0.56613 0.123579C0.398654 0.195369 0.257328 0.305137 0.157519 0.440956C0.0577093 0.576771 0.00322604 0.733447 0 0.893945V9.10599Z"
        fill="#39DD8C"
      />
    </Svg>
  )
}

export default Icon
