import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 25 15" {...props}>
      <path d="M1.99954 7.5L22.7109 7.5" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M17.1348 13L22.7109 7.5L17.1348 2"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Icon
