import React, { cloneElement, Children, ReactElement } from 'react'
import styled from 'styled-components'
import { NotificationDotProps, DotProps } from './types'

const NotificationDotRoot = styled.span`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`

const Dot = styled.span<DotProps>`
  display: ${({ show }) => (show ? 'inline-flex' : 'none')};
  position: absolute;
  top: -2px;
  right: -2px;
  width: 6px;
  height: 6px;
  pointer-events: none;
  border-radius: 50%;
  background-color: ${({ theme, color }) => theme.colors[color]};
`

const NotificationDot: React.FC<NotificationDotProps> = ({ show = false, color = 'failure', children, ...props }) => (
  <NotificationDotRoot>
    {Children.map(children, (child: ReactElement) => cloneElement(child, props))}
    <Dot show={show} color={color} />
  </NotificationDotRoot>
)

export default NotificationDot
