import React from 'react'
import { useTheme } from 'styled-components'
import { Text } from '../../components/Text'
import getThemeValue from '../../util/getThemeValue'
import {
  ModalBody,
  ModalHeader,
  ModalTitle,
  ModalContainer,
  ModalCloseButton,
  ModalBackButton,
  ModalDivine,
} from './styles'
import { ModalProps } from './types'

const Modal: React.FC<ModalProps> = ({
  title,
  onDismiss,
  onBack,
  children,
  hideCloseButton = false,
  bodyPadding = '24px',
  headerBackground = 'transparent',
  minWidth = '265px',
  maxWidth = '448px',
  textAlign = 'center',
  isLinear = false,
  isBorderHeaderBottom = true,
  ...props
}) => {
  const theme = useTheme()
  return (
    <ModalContainer minWidth={minWidth} maxWidth={maxWidth} {...props}>
      <ModalHeader background={getThemeValue(`colors.${headerBackground}`, headerBackground)(theme)}>
        {onBack ? <ModalBackButton onBack={onBack} /> : null}

        <ModalTitle>
          <Text fontSize={['18px']} textAlign={textAlign} width="100%" pt="5px" fontWeight="200">
            {title}
          </Text>
        </ModalTitle>
        {!hideCloseButton && <ModalCloseButton onDismiss={onDismiss} />}
      </ModalHeader>
      {isBorderHeaderBottom ? <ModalDivine /> : null}

      <ModalBody p={bodyPadding}>{children}</ModalBody>
    </ModalContainer>
  )
}

export default Modal
