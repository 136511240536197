import React, { useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import styled from 'styled-components'
import { Box, Flex } from '../../../../components/Box'
import { UserMenuProps, variants } from './types'
import MenuIcon from './MenuIcon'
import { UserMenuItem } from './styles'
import { Text } from '../../../../components/Text'
import useOnClickOutside from '../../../../hooks/useOnClickOutside'
import { DropdownIcon } from '../../../../components/Svg'

export const StyledUserMenu = styled(Flex)`
  align-items: center;
  background: linear-gradient(180deg, #000000 0%, #666666 100%);
  box-shadow: 4px 4px 9px 0px #0000007d;

  border-radius: 10px;
  cursor: pointer;
  display: inline-flex;
  height: 45px;

  padding: 0 10px;
  position: relative;
  border: 1px solid white;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(100%);

    background: transparent;
    width: 100%;
    height: 25px;
  }

  &:hover {
    opacity: 0.65;
  }
`

export const LabelText = styled(Text).attrs({ fontSize: ['16px'] })`
  color: white;
  display: none;
  font-weight: 500;

  ${({ theme }) => theme.mediaQueries.sm} {
    display: block;
    margin-left: 8px;
    margin-right: 4px;
  }
`

const Menu = styled.div<{ isOpen: boolean }>`
  border-radius: 8px;
  pointer-events: auto;
  visibility: visible;
  z-index: 1001;
  width: 100%;
  max-width: 275px;

  ${({ isOpen }) =>
    !isOpen &&
    `
    pointer-events: none;
    visibility: hidden;
  `}

  ${UserMenuItem}:first-child {
    border-radius: 8px 8px 0 0;
  }

  ${UserMenuItem}:last-child {
    border-radius: 0 0 8px 8px;
  }
`

const UserMenu: React.FC<UserMenuProps> = ({
  account,
  text,
  avatarSrc,
  variant = variants.DEFAULT,
  children,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null)
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null)
  const accountEllipsis = account ? `${account.substring(0, 6)}...${account.substring(account.length - 5)}` : null
  const { styles, attributes } = usePopper(targetRef, tooltipRef, {
    strategy: 'fixed',
    placement: 'bottom',
    modifiers: [{ name: 'offset', options: { offset: [-5, 20] } }],
  })

  useOnClickOutside(
    {
      current: targetRef,
    },
    () => setIsOpen(false),
  )

  // useEffect(() => {
  //   const showDropdownMenu = () => {
  //     setIsOpen(true)
  //   }

  //   const hideDropdownMenu = (evt: MouseEvent | TouchEvent) => {
  //     const target = evt.target as Node
  //     if (target && !tooltipRef?.contains(target)) {
  //       setIsOpen(false)
  //       evt.stopPropagation()
  //     }
  //   }

  //   targetRef?.addEventListener('mouseenter', showDropdownMenu)
  //   targetRef?.addEventListener('mouseleave', hideDropdownMenu)

  //   return () => {
  //     targetRef?.removeEventListener('mouseenter', showDropdownMenu)
  //     targetRef?.removeEventListener('mouseleave', hideDropdownMenu)
  //   }
  // }, [targetRef, tooltipRef, setIsOpen])

  return (
    <Flex alignItems="center" height="100%" ref={setTargetRef} {...props}>
      <StyledUserMenu
        // onTouchStart={() => {
        //   setIsOpen((s) => !s)
        // }}
        onClick={() => {
          setIsOpen((s) => !s)
        }}
      >
        <MenuIcon avatarSrc={avatarSrc} variant={variant} />
        <LabelText title={text || account}>{text || accountEllipsis}</LabelText>
        <Box ml="6px" display={['block', , , , 'none']}>
          <DropdownIcon color="transparent" width="10px" />
        </Box>
      </StyledUserMenu>
      <Menu style={styles.popper} ref={setTooltipRef} {...attributes.popper} isOpen={isOpen}>
        <Box onClick={() => setIsOpen(false)}>{children?.({ isOpen })}</Box>
      </Menu>
    </Flex>
  )
}

export default UserMenu
