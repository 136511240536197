import React from 'react'
import styled from 'styled-components'
import Flex from '../../components/Box/Flex'
import { Box } from '../../components/Box'
import {
  // ArrowBackIcon,
  CloseIcon,
  // ChevronLeftIcon
} from '../../components/Svg'
import { IconButton } from '../../components/Button'
import { ModalProps } from './types'
// import useMatchBreakpoints from '../../hooks/useMatchBreakpoints'

export const ModalHeader = styled.div<{ background?: string }>`
  align-items: center;
  background: transparent;
  display: flex;
  gap: 12px;
  position: relative;

  ${({ theme }) => theme.mediaQueries.xxs} {
    padding: 12px;
  }

  ${({ theme }) => theme.mediaQueries.xs} {
    padding: 12px 24px;
  }

  /* &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;

    height: 1px;
    background: rgba(190, 190, 190, 0.2);

    ${({ theme }) => theme.mediaQueries.xxs} {
      right: 12px;
      left: 12px;
    }

    ${({ theme }) => theme.mediaQueries.xs} {
      right: 24px;
      left: 24px;
    }
  } */
`

export const ModalDivine = styled.div`
  margin: auto;
  height: 1px;
  background: rgba(190, 190, 190, 0.2);
  width: 100%;

  ${({ theme }) => theme.mediaQueries.xs} {
    width: 100%;
  }
`

export const ModalTitle = styled(Flex)`
  align-items: center;
  flex: 1;
`

export const ModalBody = styled(Flex)`
  flex-direction: column;
  max-height: 90vh;
  overflow-y: auto;

  /* ${({ theme }) => theme.mediaQueries.xxs} {
    padding: 10px;
  }

  ${({ theme }) => theme.mediaQueries.xs} {
    padding: 24px;
  } */
`

export const ModalCloseButton: React.FC<{ onDismiss: ModalProps['onDismiss'] }> = ({ onDismiss }) => {
  // const { isMobile } = useMatchBreakpoints()

  return (
    <IconButton scale="sm" variant="text" onClick={onDismiss} aria-label="Close the dialog">
      <CloseIcon color="white" width="16px" />
    </IconButton>
  )
}

export const ModalBackButton: React.FC<{ onBack: ModalProps['onBack'] }> = ({ onBack }) => {
  return (
    <IconButton variant="text" onClick={onBack} area-label="go back" mr="8px" style={{ border: 'none' }}>
      {/* <ChevronLeftIcon width="36px" /> */}
      <img width={36} src="/images/back-button-icon.svg" alt="back-icon" />
    </IconButton>
  )
}

export const ModalContainer = styled(Box)<{ minWidth: string; maxWidth: string }>`
  overflow: hidden;
  /* background: ${({ isLinear }) => (isLinear ? 'linear-gradient(180deg, #666666 0%, #000000 100%)' : 'black')}; */
  background: black;
  box-shadow: 4px 4px 17px 0px #0000007d;
  border-radius: 20px;
  width: 100%;
  max-height: 100vh;
  z-index: ${({ theme }) => theme.zIndices.modal};
  max-width: ${({ maxWidth }) => maxWidth};

  border: 1px solid rgba(67, 67, 67, 0.5);
`

export const Dots = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

export const Dot = styled.span`
  width: 16px;
  aspect-ratio: 1/1;
  height: 100%;
  border-radius: 50%;
  border: 2px solid black;

  &:first-child {
    background-color: yellow;
  }

  &:nth-child(2) {
    background: green;
  }

  &:nth-child(3) {
    background: #3b9aff;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    width: 26px;
  }
`

export const HiddenPosition = styled.div`
  visibility: hidden;
`
