import { useDispatch } from 'react-redux'
import { Box, Button, Flex, Text, ArrowBackIcon, TrashIcon, ArrowForwardIcon } from 'packages/uikit'
import { AppDispatch } from 'state'
import { isTransactionRecent, useAllTransactions } from 'state/transactions/hooks'
import { useTranslation } from 'contexts/Localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { clearAllTransactions } from 'state/transactions/actions'
import orderBy from 'lodash/orderBy'
import TransactionRow from './TransactionRow'

const WalletTransactions: React.FC<{ onBack?: () => void }> = ({ onBack }) => {
  const { chainId } = useActiveWeb3React()
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation()
  const allTransactions = useAllTransactions()
  const sortedTransactions = orderBy(Object.values(allTransactions).filter(isTransactionRecent), 'addedTime', 'desc')

  const handleClearAll = () => {
    if (chainId) {
      dispatch(clearAllTransactions({ chainId }))
    }
  }

  console.log(sortedTransactions)

  return (
    <Box>
      <Flex alignItems="center" justifyContent="space-between" padding={['12px 0 12px 0']}>
        <Flex justifyContent="space-between" alignItems="center" width="100%">
          <Flex>
            <Text fontSize="16px">{t('Transactions:')}</Text>
          </Flex>

          {sortedTransactions.length > 0 && (
            <Button scale="sm" onClick={handleClearAll} px="0" variant="no-bg">
              <Text fontSize="12px">
                {/* {t('Clear')} */}
                <TrashIcon color="transparent" width={16} ml="6px" />
              </Text>
            </Button>
          )}
        </Flex>
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="center"
        padding="16px 0"
        maxHeight={350}
        overflowY="auto"
        borderBottom="1px solid rgba(255, 255, 255, 0.5)"
      >
        {sortedTransactions.length > 0 ? (
          sortedTransactions.map((txn) => <TransactionRow key={txn.hash} txn={txn} />)
        ) : (
          <Text fontSize="12px" textAlign="center" width="100%">
            {t('Your transactions will appear here')}
          </Text>
        )}
      </Flex>

      <Box py="16px">
        <Button width="100%" height="40px" padding="0 8px" onClick={onBack}>
          <Flex width="100%" alignItems="center" justifyContent="center" position="relative">
            <Text color="black" fontSize="16px" bold>
              Your Wallet
            </Text>
            <ArrowForwardIcon
              color="transparent"
              style={{ position: 'absolute', left: '0', transform: 'rotate(180deg)' }}
            />
          </Flex>
        </Button>
      </Box>
    </Box>
  )
}

export default WalletTransactions
