import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 14">
      <path
        d="M11.5 13.5H2C1.60218 13.5 1.22064 13.342 0.93934 13.0607C0.65804 12.7794 0.5 12.3978 0.5 12C0.5 11.6022 0.65804 11.2206 0.93934 10.9393C1.22064 10.658 1.60218 10.5 2 10.5H10.5C10.7652 10.5 11.0196 10.3946 11.2071 10.2071C11.3946 10.0196 11.5 9.76522 11.5 9.5V1.5C11.5 1.23478 11.3946 0.98043 11.2071 0.792893C11.0196 0.605357 10.7652 0.5 10.5 0.5H2C1.60901 0.499861 1.23342 0.652394 0.95324 0.925105C0.67305 1.19782 0.51043 1.56915 0.5 1.96V11.96"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.5 10.5V13.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
  )
}

export default Icon
