import React, { useContext } from 'react'
import { MenuContext } from '../../widgets/Menu/context'
import { Flex } from '../Box'
// import AnimatedIconComponent from '../Svg/AnimatedIconComponent'
import { StyledBottomNavItem, StyledBottomNavText, ActiveIconWrapper } from './styles'
import { BottomNavItemProps } from './types'
import { LinkActive, BridgeIcon } from '../Svg'
import { DropdownMenuItemType } from '../DropdownMenu/types'

const BottomNavItem: React.FC<BottomNavItemProps> = ({
  label,
  icon,
  fillIcon,
  href,
  showItemsOnMobile = false,
  isActive = false,
  commingSoon,
  type,
  ...props
}) => {
  const { linkComponent } = useContext(MenuContext)
  const bottomNavItemContent = (
    <Flex justifyContent="space-between" alignItems="center" height="100%" width="100%" position="relative">
      {/* {icon && <AnimatedIconComponent icon={icon} fillIcon={fillIcon} height="22px" width="21px" isActive={isActive} />} */}
      {isActive ? (
        <ActiveIconWrapper>
          <LinkActive width="14px" color="primary" />
        </ActiveIconWrapper>
      ) : null}
      <StyledBottomNavText textAlign="center" fontSize="14px">
        {label}

        {type === DropdownMenuItemType.EXTERNAL_LINK ? <BridgeIcon width="14px" color="transaprent" ml="10px" /> : null}
      </StyledBottomNavText>
    </Flex>
  )

  return showItemsOnMobile ? (
    <StyledBottomNavItem commingSoon={commingSoon} type="button" {...props}>
      {bottomNavItemContent}
    </StyledBottomNavItem>
  ) : (
    <StyledBottomNavItem
      commingSoon={commingSoon}
      as={linkComponent}
      href={href}
      target={type === DropdownMenuItemType.EXTERNAL_LINK ? '_blank' : ''}
      {...props}
    >
      {bottomNavItemContent}
    </StyledBottomNavItem>
  )
}

export default BottomNavItem
