import styled from 'styled-components'
import { PolymorphicComponent } from '../../util/polymorphic'
import Button from './Button'
import { BaseButtonProps } from './types'

const IconButton: PolymorphicComponent<BaseButtonProps, 'button'> = styled(Button)<BaseButtonProps>`
  padding: 0;
  width: ${({ scale }) => (scale === 'sm' ? '18px' : '26px')};
  height: ${({ scale }) => (scale === 'sm' ? '18px' : '26px')};
  aspect-ratio: 1/1;
  border: 1px solid white;
  border-radius: 50%;

  display: flex;
  justify-content: center;
`

export default IconButton
