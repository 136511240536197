import { useTranslation } from 'contexts/Localization'
import { Button, Text, Flex, Box, DisconnectIcon, ModalDivine } from 'packages/uikit'
import { setupNetwork } from 'utils/wallet'
import { useWeb3React } from '@web3-react/core'
import { InjectedConnector } from '@web3-react/injected-connector'
import { FlexGap } from 'components/Layout/Flex'
import useAuth from 'hooks/useAuth'

interface WalletWrongNetworkProps {
  onDismiss: () => void
}

const WalletWrongNetwork: React.FC<WalletWrongNetworkProps> = ({ onDismiss }) => {
  const { t } = useTranslation()
  const { connector, library } = useWeb3React()
  const { logout } = useAuth()

  const handleSwitchNetwork = async (): Promise<void> => {
    await setupNetwork(library)
    onDismiss?.()
  }

  const handleLogout = () => {
    logout()
    onDismiss?.()
  }

  return (
    <>
      <Box py="16px" px="24px">
        {/* {connector instanceof InjectedConnector && (
          <> */}
        <Button
          variant="cancel"
          onClick={handleSwitchNetwork}
          width="100%"
          mb="16px"
          style={{ background: 'rgba(64, 66, 68, 1)' }}
        >
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <FlexGap gap="6px" alignItems="center">
              <img src="/images/symbol/hyb-icon-black.png" width={26} height={26} alt="" />
              <Text small lineHeight="1">
                Hybrid
              </Text>
            </FlexGap>

            <FlexGap gap="6px" alignItems="center">
              <Box width="6px" height="6px" background="#67FDB3" borderRadius="50%" />
              <Text small lineHeight="1">
                Confirm in Wallet
              </Text>
            </FlexGap>
          </Flex>
        </Button>
        {/* </>
        )} */}
        <Text small fontWeight="200">
          {t('Wrong network detected, switch or disconnect to continue.')}
        </Text>
      </Box>

      <ModalDivine />

      <Box pt="16px" px="24px">
        <Button variant="text" height="auto" p="0" onClick={handleLogout}>
          <FlexGap gap="6px" alignItems="center">
            <DisconnectIcon />
            <Text fontSize="13px" bold color="#ff0000">
              Disconnect
            </Text>
          </FlexGap>
        </Button>
      </Box>
    </>
  )
}

export default WalletWrongNetwork
