import { useState } from 'react'
import { Box, VelasIcon, AbstractTestnetIcon, BifinityIcon, InkIcon, Text, Flex, Checkbox } from 'packages/uikit'
import { CHAIN_ID } from 'config/constants/networks'
import { useRouter } from 'next/router'
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'
import Wrapper from './Wrapper'

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
`

const NetworkWrapper = styled(Box).attrs({ pt: '10px', pb: '16px' })`
  box-shadow: 4px 4px 17px 0px rgba(0, 0, 0, 0.49);
  border: 1px solid rgba(67, 67, 67, 0.5);
  border-radius: 20px;
  background: rgba(0, 0, 0, 1);
  overflow: hidden;
`

const Network = styled.div`
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: 0;

  width: 100%;
  height: 35px;

  &:hover:not(:disabled) {
    background-color: transparent;
  }

  &:active:not(:disabled) {
    opacity: 0.85;
    transform: translateY(1px);
  }
`

enum Type {
  MAINNET = 'mainnet',
  TESTNET = 'testnet',
}

interface Chain {
  url: string
  label: string
  Icon: React.FC<React.SVGProps<SVGSVGElement>>
  chainId: number
  networkType?: Type
}

const chains: Chain[] = [
  { url: '/', label: 'Hybrid Testnet', networkType: Type.TESTNET, Icon: VelasIcon, chainId: 1225 },
  {
    url: 'https://abs.hybridex.xyz/',
    networkType: Type.TESTNET,
    label: 'Abstract Testnet',
    Icon: AbstractTestnetIcon,
    chainId: 11124,
  },
  { url: 'https://ink.hybridex.xyz/', networkType: Type.TESTNET, label: 'Ink Sepolia', Icon: InkIcon, chainId: 763373 },
  {
    url: 'https://bitfinity.hybridex.xyz/',
    networkType: Type.MAINNET,
    label: 'Bitfinity',
    Icon: BifinityIcon,
    chainId: 355110,
  },
]

const NetworkSelect: React.FC = () => {
  const { t } = useTranslation()
  const origin = typeof window !== 'undefined' ? window.location.origin : ''
  const selectedOne = chains.find((c) => c.url.toLowerCase() === origin.toLowerCase())
  const [onlyMainnet, setOnlyMainnet] = useState(false)

  let displayList = chains

  if (onlyMainnet) {
    displayList = chains.filter((chain) => chain.networkType === Type.MAINNET)
  }

  return (
    <>
      <NetworkWrapper>
        <Box borderBottom="1px solid rgba(255, 255, 255, 0.5)" mb="12px">
          <Text fontSize="16px" textAlign="center" mb="12px">
            Select network
          </Text>
        </Box>
        <Box minHeight="75px" mb="15px" px="10px">
          {displayList.map((chain) => (
            <Network
              key={chain.url}
              onClick={() => {
                if (window) {
                  window.open(chain.url, '_blank')
                }
              }}
            >
              <Flex style={{ cursor: 'pointer' }}>
                {chain.Icon && <chain.Icon mr="8px" />}
                <Text fontSize="16px" fontWeight="300" bold mr="8px">
                  {chain.label}
                </Text>
              </Flex>

              {chain.chainId === +CHAIN_ID ? (
                <Box borderRadius="50%" width="8px" height="8px" background="rgba(57, 221, 140, 1)" />
              ) : null}
            </Network>
          ))}
        </Box>
        <Flex alignItems="center" px="10px">
          <Checkbox
            name="confirmed"
            type="checkbox"
            checked={onlyMainnet}
            onChange={() => setOnlyMainnet((prev) => !prev)}
            scale="mini"
            style={{ margin: '0 5px 0 0' }}
          />
          <Text fontWeight="300" fontSize="12px">
            Only Mainnet
          </Text>
        </Flex>
      </NetworkWrapper>
    </>
  )
}

export const NetworkSwitcher: React.FC = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const origin = typeof window !== 'undefined' ? window.location.origin : ''

  return (
    <StyledBox height="100%">
      <Wrapper mr="8px" placement="bottom" variant="default" text={t(chains[0]?.label)}>
        {() => <NetworkSelect />}
      </Wrapper>
    </StyledBox>
  )
}
