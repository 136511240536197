import React, { useState, memo } from 'react'
import BottomNavItem from '../BottomNavItem'
import StyledBottomNav from './styles'
import { Box, Flex } from '../Box'
import DropdownMenu from '../DropdownMenu/DropdownMenu'
import { BottomNavProps } from './types'
import { NotificationDot } from '../NotificationDot'
import { Overlay } from '../Overlay'
import { Link } from '../Link'
import { BridgeIcon, TwitterIcon, TelegramIcon, DocumentIcon } from '../Svg'

const BottomNav: React.FC<BottomNavProps> = ({ items = [], activeItem = '', activeSubItem = '', ...props }) => {
  const [menuOpenByIndex, setMenuOpenByIndex] = useState({})
  const isBottomMenuOpen = Object.values(menuOpenByIndex).reduce((acc, value) => acc || value, false)
  return (
    <>
      {isBottomMenuOpen && <Overlay />}
      <StyledBottomNav background="rgba(48, 48, 48, 1)" {...props}>
        <Box padding="10px 20px 0px">
          {items.map(
            (
              {
                label,
                items: menuItems,
                href,
                icon,
                fillIcon,
                showOnMobile = true,
                showItemsOnMobile = true,
                commingSoon,
                type,
              },
              index,
            ) => {
              const statusColor = menuItems?.find((menuItem) => menuItem.status !== undefined)?.status?.color
              return (
                showOnMobile && (
                  <DropdownMenu
                    key={label}
                    items={menuItems}
                    isBottomNav
                    activeItem={activeSubItem}
                    showItemsOnMobile={showItemsOnMobile}
                    setMenuOpenByIndex={setMenuOpenByIndex}
                    index={index}
                    mx="auto"
                    width="100%"
                    height="100%"
                  >
                    <Box margin="auto auto">
                      <NotificationDot show={!!statusColor} color={statusColor}>
                        <BottomNavItem
                          href={href}
                          isActive={href === activeItem}
                          label={label}
                          icon={icon}
                          fillIcon={fillIcon}
                          showItemsOnMobile={showItemsOnMobile}
                          commingSoon={commingSoon}
                          type={type}
                        />
                      </NotificationDot>
                    </Box>
                  </DropdownMenu>
                )
              )
            },
          )}
          <Box width="100%" height="1px" background="rgba(255, 255, 255, 0.5)" mt="16px" />

          <Flex justifyContent="space-evenly" padding="16px 0 16px">
            <Link href="https://x.com/Hybrid_Ex" external>
              <TwitterIcon color="transparent" />
            </Link>

            <Link href="https://t.me/Hybrid_Ex" external>
              <TelegramIcon width="18px" color="transparent" />
            </Link>

            <Link href="https://docs.hybridex.xyz/" external>
              <DocumentIcon width="16px" color="transparent" />
            </Link>
          </Flex>
        </Box>
      </StyledBottomNav>
    </>
  )
}

export default memo(BottomNav)
