import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 15">
      <path
        d="M15.5 3.5L14.09 4.91L15.67 6.5H6V8.5H15.67L14.09 10.08L15.5 11.5L19.5 7.5L15.5 3.5ZM2 2H11V6H13V2C13 0.89543 12.1046 0 11 0H9H2C0.9 0 0 0.9 0 2V13C0 14.1 0.9 15 2 15H11C12.1046 15 13 14.1046 13 13V9H11V13H2V2Z"
        fill="white"
      />
    </Svg>
  )
}

export default Icon
