import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 15 13">
      <path
        d="M4.28748 9.07185C3.52242 9.67278 2.64429 10.1137 1.70534 10.3683C1.60192 10.4059 1.51256 10.4743 1.44942 10.5643C1.38627 10.6544 1.35239 10.7618 1.35239 10.8718C1.35239 10.9819 1.38627 11.0892 1.44942 11.1793C1.51256 11.2694 1.60192 11.3379 1.70534 11.3754C9.11963 14.4183 13.8339 9.71469 13.116 4.49685L14.316 2.01114H12.9232C11.1875 -0.121003 6.58034 -0.0138601 7.34106 4.529C7.34106 4.529 4.87677 4.96828 1.72677 1.87185C1.6539 1.79992 1.56194 1.75036 1.46178 1.72907C1.36163 1.70778 1.25747 1.71564 1.16164 1.75171C1.06582 1.78779 0.982333 1.85057 0.921083 1.93263C0.859835 2.01468 0.823386 2.11258 0.816052 2.21471C0.482979 3.58383 0.649664 5.02689 1.28609 6.28403C1.92252 7.54117 2.98687 8.52978 4.28748 9.07185Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Icon
