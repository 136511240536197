/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
// import { baseColors, darkColors, lightColors } from '../../theme/colors'
import { Flex } from '../Box'
import { Link } from '../Link'
import { StyledFooter, SocialWrapper, RobotWrapper } from './styles'
// import { FooterProps } from './types'
// import { ThemeSwitcher } from '../ThemeSwitcher'
// import LangSelector from '../LangSelector/LangSelector'
// import CakePrice from '../CakePrice/CakePrice'
// import { LogoWithTextIcon, ArrowForwardIcon } from '../Svg'
// import { Button } from '../Button'
// import { Colors } from '../..'
import { FooterProps } from './types'
import { TelegramIcon, TwitterIcon } from '../Svg'
import { Text } from '../Text'

const MenuItem: React.FC<FooterProps> = ({ ...props }) => {
  return (
    <StyledFooter {...props}>
      <Flex flexDirection="column" mr="16px">
        <Flex alignItems="center">
          <img src="/images/footer-logo.svg" alt="footer-logo" />

          <Text fontFamily="Righteous" fontSize={35} color="black" ml="8px">
            HybridEx
          </Text>
        </Flex>
        <Text fontSize={14} color="black" mb="5px">
          © 2024 HybridEx. All rights reserved.
        </Text>
      </Flex>

      <Flex style={{ gap: '6px' }} mr={['0', , , , '8px']} justifyContent="center" alignItems="center">
        <SocialWrapper>
          <Link external href="https://x.com/Hybrid_Ex">
            {/* <TwitterIcon width="21px" color="transparent" /> */}
            <img src="/images/footer-twitter.svg" alt="" />
          </Link>
        </SocialWrapper>

        <SocialWrapper>
          <Link external href="https://t.me/Hybrid_Ex">
            {/* <TelegramIcon width="18px" color="transparent" /> */}
            <img src="/images/footer-telegram.svg" alt="" />
          </Link>
        </SocialWrapper>
      </Flex>

      <RobotWrapper>
        <img src="/images/robot.svg" alt="robot" />
      </RobotWrapper>
    </StyledFooter>
  )
}

export default MenuItem
