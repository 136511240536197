import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 22">
      <path
        d="M11 22C17.0752 22 22 17.0752 22 11C22 4.92487 17.0752 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0752 4.92487 22 11 22Z"
        fill="#F43B3A"
      />
      <path
        d="M11.0045 17.2863C7.53295 17.2863 4.71875 14.4721 4.71875 11.0006C4.71875 7.52905 7.53295 4.71484 11.0045 4.71484C14.4759 4.71484 17.2902 7.52905 17.2902 11.0006C17.2902 14.4721 14.4759 17.2863 11.0045 17.2863Z"
        fill="#F43B3A"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M15.4591 15.4504L6.5625 6.55371L15.4591 15.4504Z" fill="#F43B3A" />
      <path
        d="M15.4591 15.4504L6.5625 6.55371"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Icon
