import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path
        d="M7.43285 1.95589H7.42969C8.7042 0.681371 10.7706 0.681371 12.0452 1.95589C13.3197 3.23041 13.3197 5.29681 12.0452 6.57133"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.57132 12.0432C5.29681 13.3177 3.23041 13.3177 1.95589 12.0432C0.681371 10.7687 0.681372 8.70226 1.95589 7.42773"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.8486 5.15332L5.15625 8.84567" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
  )
}

export default Icon
