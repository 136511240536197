import styled from 'styled-components'
import { Box } from '../Box'
// import DropdownMenu from '../DropdownMenu/DropdownMenu'

const StyledBottomNav = styled(Box)`
  /* position: absolute; */
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid black;
  overflow: hidden;

  border: 1px solid rgba(255, 255, 255, 0.5);

  padding-bottom: env(safe-area-inset-bottom);
  html[data-useragent*='TokenPocket_iOS'] & {
    padding-bottom: 45px;
  }
  z-index: 20;
  min-height: 60px;

  display: flex;
  flex-direction: column;
  top: 0;
`

export default StyledBottomNav

// export const StyledDropdownMenu = styled(DropdownMenu)`
//   overflow: hidden;

//   &:first-child {
//     border-top-right-radius: 16px;
//     border-top-left-radius: 16px;
//   }

//   &:last-child {
//     border-bottom-right-radius: 16px;
//     border-bottom-left-radius: 16px;
//   }
// `
