import throttle from 'lodash/throttle'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import BottomNav from '../../components/BottomNav'
import { Box } from '../../components/Box'
import Flex from '../../components/Box/Flex'
import MenuItems from '../../components/MenuItems/MenuItems'
import { SubMenuItems } from '../../components/SubMenuItems'
import { useMatchBreakpoints, useOnClickOutside } from '../../hooks'
import CakePrice from '../../components/CakePrice/CakePrice'
import Logo from './components/Logo'
import {
  MENU_HEIGHT,
  //  MOBILE_MENU_HEIGHT,
  TOP_BANNER_HEIGHT,
  TOP_BANNER_HEIGHT_MOBILE,
  // FOOTER_HEIGHT,
  // CONTAINER,
} from './config'
import { NavProps } from './types'
// import LangSelector from '../../components/LangSelector/LangSelector'
import { MenuContext } from './context'
import { Button } from '../../components/Button'
import { MenuMobile, TwitterIcon, BridgeIcon, TelegramIcon, DocumentIcon } from '../../components/Svg'
import Footer from '../../components/Footer'
import { Text } from '../../components/Text'
import { Link } from '../../components/Link'

// const GROUND_HEIGHT = 95

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;

  overflow-x: hidden;
  flex-direction: column;
  background: linear-gradient(180deg, #37fe9c 0%, #39d78a 85.02%, #3d976b 100%);

  &:before {
    content: '';
    z-index: 0;
    top: calc(80px - 2px);
    position: absolute;
    background: url('/images/bg.svg') repeat;
    background-repeat: no-repeat;
    background-size: contain;

    width: 100%;
    height: 100%;
    max-height: 790px;

    ${({ theme }) => theme.mediaQueries.sm} {
      background-repeat: repeat;
    }
  }
`

const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: transparent;
  transform: translate3d(0, 0, 0);
  padding: 0;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 16px 0;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 16px 0;
  }
`

const FixedContainer = styled.div<{ showMenu: boolean; height: number }>`
  position: relative;
  /* top: ${({ showMenu, height }) => (showMenu ? 0 : `-${height}px`)};
  left: 0; */
  transition: top 0.2s;
  width: 100%;
  z-index: 20;
  background: linear-gradient(180deg, #052d18 0%, #11934f 100%);
  height: 80px;
`

const BodyWrapper = styled(Box)`
  position: relative;
  /* display: flex; */
  margin: 0 auto;
  width: 100%;
`

const Inner = styled.div<{ isPushed: boolean; showMenu: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  z-index: 1;

  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;
  background: transparent;
`
const StyledFlex = styled(Flex)`
  position: absolute;
  bottom: 0;
  right: 16px;
  transform: translate(0, 100%);
  width: 183px;

  ${({ theme }) => theme.mediaQueries.sm} {
    gap: 5px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    gap: 24px;
  }
`

const MenuDesktopPosition = styled(Flex).attrs({ alignItems: 'center', justifyContent: 'center' })`
  margin: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`

const SocialsMobilePosition = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(calc(100% + 35px));

  width: 310px;
  background: #303030;
  border: 1px solid #000000;
  border-radius: 10px;
  box-shadow: 4px 4px 17px 0px #0000007d;
`

const StyledLink = styled(Link)`
  width: 100%;
  justify-content: space-between;
`

const Menu: React.FC<NavProps> = ({
  linkComponent = 'a',
  userMenu,
  banner,
  globalMenu,
  isDark,
  toggleTheme,
  currentLang,
  setLang,
  cakePriceUsd,
  links,
  subLinks,
  footerLinks,
  activeItem,
  activeSubItem,
  langs,
  buyCakeLabel,
  children,
}) => {
  const { isMobile, isMd } = useMatchBreakpoints()
  const [showMenu, setShowMenu] = useState(true)
  const refPrevOffset = useRef(typeof window === 'undefined' ? 0 : window.pageYOffset)

  const topBannerHeight = isMobile ? TOP_BANNER_HEIGHT_MOBILE : TOP_BANNER_HEIGHT

  const totalTopMenuHeight = banner ? MENU_HEIGHT + topBannerHeight : MENU_HEIGHT

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight
      const isTopOfPage = currentOffset === 0
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true)
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current || currentOffset <= totalTopMenuHeight) {
          // Has scroll up
          setShowMenu(true)
        } else {
          // Has scroll down
          setShowMenu(false)
        }
      }
      refPrevOffset.current = currentOffset
    }
    const throttledHandleScroll = throttle(handleScroll, 200)

    window.addEventListener('scroll', throttledHandleScroll)
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [totalTopMenuHeight])

  // Find the home link if provided
  const homeLink = links.find((link) => link.label === '/')

  const subLinksWithoutMobile = subLinks?.filter((subLink) => !subLink.isMobileOnly)
  const subLinksMobileOnly = subLinks?.filter((subLink) => subLink.isMobileOnly)

  const [isBottomNavOpen, setIsBottomNavOpen] = useState(false)
  const bottomNavRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (bottomNavRef.current && !bottomNavRef.current.contains(event.target as Node)) {
        setIsBottomNavOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [bottomNavRef])

  const [isOpenSocial, setIsOpenSocial] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useOnClickOutside(ref, () => setIsOpenSocial(false))

  const router = useRouter()
  const isHomePage = router.pathname === '/'

  return (
    <MenuContext.Provider value={{ linkComponent }}>
      <Wrapper>
        <FixedContainer showMenu={showMenu} height={totalTopMenuHeight}>
          <Box mx="auto" px="25px">
            <StyledNav justifyContent="space-between">
              {!isMobile ? (
                <>
                  <Flex
                    justifyContent="space-between"
                    width="100%"
                    height="100%"
                    alignItems="center"
                    position="relative"
                  >
                    <Flex alignItems="center">
                      <Logo isDark={isDark} href={homeLink?.href ?? '/swap'} />
                    </Flex>

                    <MenuDesktopPosition>
                      <MenuItems items={links} activeItem={activeItem} activeSubItem={activeSubItem} />
                    </MenuDesktopPosition>

                    <Flex alignItems="center">
                      {globalMenu} {userMenu}
                    </Flex>
                  </Flex>
                </>
              ) : (
                <>
                  <Box mr="8px">
                    <Logo isDark={isDark} href={homeLink?.href ?? '/swap'} />
                  </Box>

                  <Flex
                    width="100%"
                    height="100%"
                    alignItems="center"
                    justifyContent="flex-end"
                    position="relative"
                    ref={bottomNavRef}
                    padding="16px 0"
                  >
                    <Flex alignItems="center" height="100%">
                      {!isMobile && !isMd && (
                        <Box mr="12px">
                          <CakePrice showSkeleton={false} cakePriceUsd={cakePriceUsd} />
                        </Box>
                      )}
                      {globalMenu} {userMenu}
                      <Box>
                        <Button
                          onClick={() => setIsBottomNavOpen(!isBottomNavOpen)}
                          padding="0"
                          ml="12px"
                          variant="no-bg"
                        >
                          <img width={16} src="/images/four-dot.svg" alt="" />
                        </Button>

                        {isBottomNavOpen && (
                          <StyledFlex>
                            <BottomNav items={links} activeItem={activeItem} activeSubItem={activeSubItem} />
                          </StyledFlex>
                        )}
                      </Box>
                    </Flex>
                  </Flex>
                </>
              )}

              <Box display={['none', , , , 'block']}>
                <Flex ml={['0px', , , '16px']} position="relative" ref={ref}>
                  <Button variant="text" padding="0" height="auto" onClick={() => setIsOpenSocial((prev) => !prev)}>
                    <img src="/images/four-dot.svg" alt="" />
                  </Button>

                  {isOpenSocial ? (
                    <SocialsMobilePosition>
                      <Box padding="12px 20px 15px" width="100%">
                        <Flex justifyContent="space-between" width="100%" mb="6px">
                          <StyledLink href="https://hybrid-testnet.bridge.caldera.xyz/" external>
                            <Text>Bridge</Text>

                            <BridgeIcon color="transparent" />
                          </StyledLink>
                        </Flex>

                        <Flex justifyContent="space-between" width="100%" mb="6px">
                          <StyledLink href="https://x.com/Hybrid_Ex" external>
                            <Text>Twitter</Text>

                            <TwitterIcon color="transparent" />
                          </StyledLink>
                        </Flex>

                        <Flex justifyContent="space-between" width="100%" mb="6px">
                          <StyledLink href="https://t.me/Hybrid_Ex" external>
                            <Text>Telegram</Text>

                            <TelegramIcon width="18px" color="transparent" />
                          </StyledLink>
                        </Flex>

                        <Flex justifyContent="space-between" width="100%">
                          <StyledLink href="https://docs.hybridex.xyz/" external>
                            <Text>Docs</Text>

                            <DocumentIcon width="16px" color="transparent" />
                          </StyledLink>
                        </Flex>
                      </Box>
                    </SocialsMobilePosition>
                  ) : null}
                </Flex>
              </Box>
            </StyledNav>
          </Box>
        </FixedContainer>
        {subLinks && (
          <Flex justifyContent="space-around">
            {/* {isMobile && (
              <SubMenuItems
                items={subLinksWithoutMobile}
                mt={`${totalTopMenuHeight - 90}px`}
                activeItem={activeSubItem}
              />
            )} */}
          </Flex>
        )}
        <BodyWrapper>
          {isMobile && <SubMenuItems items={subLinksWithoutMobile} activeItem={activeSubItem} />}
          <Inner isPushed={false} showMenu={showMenu}>
            {children}
          </Inner>
        </BodyWrapper>

        {!isHomePage ? (
          <Box mt="auto" background="transparent" zIndex="2" position="relative">
            <Footer />
          </Box>
        ) : null}
      </Wrapper>
    </MenuContext.Provider>
  )
}

export default Menu
