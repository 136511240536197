import React, { useState } from 'react'
import styled from 'styled-components'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import { Flex, UserMenuProps, useModal, MetamaskIcon, Text, Box } from 'packages/uikit'
import { UserMenuItemProps } from 'packages/uikit/src/widgets/Menu/components/UserMenu/types'
import { usePopper } from 'react-popper'
import useOnClickOutside from 'packages/uikit/src/hooks/useOnClickOutside'
import WalletModal, { WalletView } from './WalletModal'

const UserMenuItem = styled.button<UserMenuItemProps>`
  align-items: center;
  border: 0;
  background: transparent;
  color: ${({ theme, disabled }) => theme.colors[disabled ? 'black' : 'black']};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  outline: 0;
  width: 100%;
  padding: 10px 0;

  &:hover:not(:disabled) {
    background-color: transparent;
  }

  &:active:not(:disabled) {
    opacity: 0.85;
    transform: translateY(1px);
  }
`

export const StyledUserMenu = styled(Flex)`
  align-items: center;
  background: linear-gradient(180deg, #000000 0%, #666666 100%);
  box-shadow: 4px 4px 9px 0px #0000007d;

  border-radius: 10px;
  cursor: pointer;
  display: inline-flex;
  height: 45px;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  border: 1px solid white;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(100%);

    background: transparent;
    width: 100%;
    height: 25px;
  }

  &:hover {
    opacity: 0.65;
  }
`

export const LabelText = styled(Text).attrs({ fontSize: ['18px'], bold: true, display: ['none', , , , 'block'] })`
  color: white;
  margin-left: 8px;
  margin-right: 4px;
`

const Menu = styled.div<{ isOpen: boolean }>`
  border-radius: 8px;
  padding-bottom: 4px;
  padding-top: 4px;
  pointer-events: auto;
  visibility: visible;
  z-index: 1001;
  width: 100%;

  ${({ isOpen }) =>
    !isOpen &&
    `
    pointer-events: none;
    visibility: hidden;
  `}

  ${({ theme }) => theme.mediaQueries.xxs} {
    max-width: 320px;
  }

  ${({ theme }) => theme.mediaQueries.xs} {
    max-width: 420px;
  }

  ${UserMenuItem}:first-child {
    border-radius: 8px 8px 0 0;
  }

  ${UserMenuItem}:last-child {
    border-radius: 0 0 8px 8px;
  }
`

const UserMenuWrapper: React.FC<UserMenuProps> = ({ children, account, text, ...props }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null)
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null)
  const accountEllipsis = account ? `${account.substring(0, 6)}...${account.substring(account.length - 5)}` : null
  const { styles, attributes } = usePopper(targetRef, tooltipRef, {
    strategy: 'fixed',
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
  })

  const { error } = useWeb3React()
  const isWrongNetwork: boolean = error && error instanceof UnsupportedChainIdError

  const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  const [onPresentWrongNetworkModal] = useModal(<WalletModal initialView={WalletView.WRONG_NETWORK} />)

  const toggleDropdownMenu = () => setIsOpen((s) => !s)

  useOnClickOutside(
    {
      current: targetRef,
    },
    () => setIsOpen(false),
  )

  const onClickWalletMenu = (): void => {
    if (isWrongNetwork) {
      onPresentWrongNetworkModal()
    } else {
      onPresentWalletModal()
    }
  }

  return (
    <Flex alignItems="center" height="100%" ref={setTargetRef} {...props}>
      <StyledUserMenu
        onClick={onClickWalletMenu}
        onTouchStart={() => {
          setIsOpen((s) => !s)
        }}
      >
        <Flex
          width={36}
          height={36}
          borderRadius="50%"
          border="1px solid white"
          background="transparent"
          justifyContent="center"
          alignItems="center"
        >
          <MetamaskIcon width="26px" />
        </Flex>
        <LabelText title={text || account}>{text || accountEllipsis}</LabelText>
      </StyledUserMenu>

      <Menu
        style={styles.popper}
        ref={setTooltipRef}
        {...attributes.popper}
        isOpen={isOpen}
        // isWrongNetWork={isWrongNetWork}
      >
        <Box>{children?.({ isOpen })}</Box>
      </Menu>
    </Flex>
  )
}

export default UserMenuWrapper
